import React, {useState} from "react";
import {gtagCustomEvent, useFormInput} from "../../utils/global-functions";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {graphql, useStaticQuery} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const ModalContactSales = ({pageContext, product, demo}) => {
    const lang = pageContext.locale.path.default;
    const query = useStaticQuery(graphql`
    query {
        allStrapiContactarConVentas {
            edges {
                node {
                    id
                    title
                    description
                    first_name
                    last_name
                    email
                    phone
                    company
                    company_size
                    message
                    terms_conditions
                    button
                    success_message
                    fail_message
                    locale
                }
            }
        }
    }`);
    const page = query.allStrapiContactarConVentas.edges.filter(post => post.node.locale === lang)[0].node;
    const name = useFormInput("")
    const surname = useFormInput("")
    const email = useFormInput("")
    const company = useFormInput("")
    const companySize = useFormInput("")
    const phone = useFormInput("")
    const message = useFormInput("")
    const conditions = useFormInput("")

    const {executeRecaptcha} = useGoogleReCaptcha()
    const [success, setSuccess] = useState("")
    const [fail, setFail] = useState("")

    let title = page.title;
    let description = page.description;
    let image = null;

    const employees = lang === 'es' ? 'empleados' : 'employees';

    if (product) {
        title = `👉 ${page.title}`;

        if (product.toLowerCase() === 'signature') {
            image = 'contact.svg';
        } else if (product.toLowerCase() === 'identity') {
            image = 'contact1.svg';
        } else if (product.toLowerCase() === 'dxbot') {
            image = 'contact2.svg';
        }
    }

    if (demo) {
        title = '👉 ¿Quieres probar ' + product + '?';
        description = 'Rellena este formulario para porder probar sin ningún tipo de compromiso Tecalis ' + product + '.';
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            console.error("executeRecaptcha fail");
            return
        }

        const token = await executeRecaptcha("homepage")

        const json = {
            "contactSalesName": name.value,
            "contactSalesSurname": surname.value,
            "contactSalesEmail": email.value,
            "contactSalesOrganization": company.value,
            "contactSalesOrganizationSize": companySize.value,
            "contactSalesPhone": phone.value,
            "contactSalesMessage": message.value,
            "product": product,
            "g-recaptcha-response": token
        }
        const data = JSON.stringify(json);
        const contactSalesForm = document.getElementById("contact-sales-form")
        const btnSend = document.getElementById("btn-contact-sales-send")

        contactSalesForm.style.opacity = "0.5"
        btnSend.innerText = "Enviando..."
        btnSend.disabled = true

        fetch(process.env.SPIRIT_URL + "contact-sales-form", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
            .then(response => {
                if (response.ok) return response.json()

                throw new Error("No enviado. No ha sido posible registrar tu mensaje, por favor, contáctanos de otro modo")
            })
            .then(data => {
                contactSalesForm.style.opacity = "1"
                contactSalesForm.reset()
                clearForm()
                btnSend.innerText = page.button
                btnSend.disabled = false
                setSuccess(data.msg)
                setFail("")
                hideAlerts()

                gtagCustomEvent("send-form.sales", {
                    from: window.location.href,
                    data: json
                })
            })
            .catch(error => {
                contactSalesForm.style.opacity = "1"
                btnSend.innerText = page.button
                btnSend.disabled = false
                setFail(error.message)
                setSuccess("")
                hideAlerts()
            })
    }

    const hideAlerts = () => {
        setTimeout(() => {
            setSuccess("")
            setFail("")
        }, 10000)
    }

    const clearForm = () => {
        name.clearValue()
        email.clearValue()
        company.clearValue()
        phone.clearValue()
        message.clearValue()
    }

    return (
        <div className="modal fade" id="modal-contact-sales" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className={(product ? 'modal-xl' : 'modal-lg') + ' modal-dialog modal-dialog-centered'}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="container">
                            <button type="button" className="modal-close1 close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>

                            <div className="row">
                                <div className={product ? 'col-xl-6' : 'col-xl-12'}>
                                    <h2 className={(product ? '' : 'text-center ') + 'mb-0 font-weight-bold spacing'} id="modalSigninHorizontalTitle">
                                        {title}
                                    </h2>

                                    <br/>

                                    <p className="mb-6  text-muted spacing">
                                        {description}
                                    </p>

                                    <form id="contact-sales-form" className="mb-6" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="contactSalesName">
                                                        {page.first_name}
                                                    </label>

                                                    <input {...name} type="text" className="form-control spacing" id="contactSalesName" name="contactSalesName"
                                                           placeholder={page.first_name} required/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="contactSalesSurname">
                                                        {page.last_name}
                                                    </label>
                                                    <input {...surname} type="text" className="form-control spacing" id="contactSalesSurname" name="contactSalesSurname"
                                                           placeholder={page.last_name} required/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="contactSalesEmail">
                                                        {page.email}
                                                    </label>

                                                    <input {...email} type="email" className="form-control spacing" id="contactSalesEmail" name="contactSalesEmail"
                                                           placeholder={page.email} required/>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="contactSalesPhone">
                                                        {page.phone}
                                                    </label>

                                                    <input {...phone} type="text" className="form-control spacing" id="contactSalesPhone" name="contactSalesPhone"
                                                           placeholder={page.phone} required/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="contactSalesOrganization">
                                                        {page.company}
                                                    </label>

                                                    <input {...company} type="text" className="form-control spacing" id="contactSalesOrganization" name="contactSalesOrganization"
                                                           placeholder={page.company}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <select {...companySize} className="form-control spacing" name="contactSalesOrganizationSize" id="contactSalesOrganizationSize">
                                                        <option>{page.company_size}</option>
                                                        <option value="1">{`0 - 1 ${employees}`}</option>
                                                        <option value="2">{`2 - 10 ${employees}`}</option>
                                                        <option value="3">{`11 - 50 ${employees}`}</option>
                                                        <option value="4">{`51 - 200 ${employees}`}</option>
                                                        <option value="5">{`201 - 500 ${employees}`}</option>
                                                        <option value="6">{`501 - 1,000 ${employees}`}</option>
                                                        <option value="7">{`1001 - 5,000 ${employees}`}</option>
                                                        <option value="8">{`5001 - 10,000 ${employees}`}</option>
                                                        <option value="9">{`10,001+ ${employees}`}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="contactSalesMessage"/>
                                                    <textarea {...message} className="form-control spacing" id="contactSalesMessage" name="contactSalesMessage" rows="3"
                                                              placeholder={page.message}/>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div align="left">
                                                        <div className="checkbox icheck-primary spacing">
                                                            <input {...conditions} type="checkbox" id="contactSalesConditions" name="contactSalesConditions" required/>
                                                            <label htmlFor="contactSalesConditions">
                                                                <ReactMarkdown children={page.terms_conditions} rehypePlugins={[rehypeRaw]} components={customElements('', 'text-tecalis')} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {success && <div className="alert alert-success mt-2 animate__animated animate__fadeInDown" role="alert">
                                            <div align="center" className="font-alert spacing">{page.success_message}🙂</div>
                                        </div>}

                                        {fail && <div className="alert alert-danger mt-2 animate__animated animate__fadeInDown" role="alert">
                                            <div align="center" className="font-alert spacing">{page.fail_message}😔</div>
                                        </div>}

                                        <button id="btn-contact-sales-send" className="btn btn-primary btn-sm mt-3 spacing" type="submit">
                                            {page.button}
                                        </button>
                                    </form>
                                </div>

                                {image != null &&
                                <>
                                    <div className="col-xl-1"/>
                                    <div className="col-xl-5 contact-img">
                                        <img src={require("../../img/products/" + image).default} className="img-fluid" alt="..."/>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalContactSales
